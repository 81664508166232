<template>
  <main class="publication">
    <PublicationBanner />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </main>
</template>

<script>
import PublicationBanner from '@/components/Publication/PublicationBanner.vue'

export default {
  components: {
    PublicationBanner
  }
}
</script>

<style lang="scss"></style>
