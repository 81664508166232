<template>
  <BaseBanner>
    <img
      class="publicationBanner__lightImg"
      :src="pageInfo.banner"
      alt=""
    />

    <div class="publicationBanner__textContainer container">
      <h2 class="publicationBanner__title" :style="titleImageStyle">
        {{ pageInfo.title }}
      </h2>
      <router-link
        :to="{ name: pageInfo.toAnotherLinkName }"
        class="publicationBanner__button clickable"
        :title="pageInfo.toAnotherLinkTitle"
        :style="`background-image: url(${pageInfo.buttonImage})`"
      >
        {{ pageInfo.buttonTitle }}
      </router-link>
    </div>
  </BaseBanner>
</template>

<script>
import BaseBanner from '@/components/BaseBanner.vue'

export default {
  components: {
    BaseBanner
  },
  data() {
    return {
      pageTable: {
        PublicationQuery: {
          banner: require('@/assets/image/Publication/Light.png'),
          title: '典藏查詢',
          titleImage: require('@/assets/image/Publication/PublicationTitle.png'),
          toAnotherLinkName: 'Publication3D',
          toAnotherLinkTitle: '點擊前往典藏-3D文物檢索',
          buttonImage: require('@/assets/image/Publication/3DButton.png'),
          buttonTitle: '3D文物檢索'
        },
        Publication3D: {
          banner: require('@/assets/image/Publication/3D.png'),
          title: '3D文物檢索',
          titleImage: require('@/assets/image/Publication/3DTitle.png'),
          toAnotherLinkName: 'PublicationQuery',
          toAnotherLinkTitle: '點擊前往典藏-典藏查詢',
          buttonImage: require('@/assets/image/Publication/PublicationButton.png'),
          buttonTitle: '典藏查詢'
        }
      }
    }
  },
  computed: {
    pageInfo() {
      const routeName = this.$route.name
      return this.pageTable[routeName]
    },
    titleImageStyle() {
      const routeName = this.$route.name
      const titleImage = this.pageInfo.titleImage
      if (routeName === 'Publication3D') {
        return `background-image: url(${titleImage}); width: 20rem;`
      }
      return `background-image: url(${titleImage});`
    }
  }
}
</script>

<style lang="scss">
.publicationBanner {
  &__textContainer {
    padding-left: 23.1rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__lightImg {
    width: 13.15rem;
    height: 16.75rem;
    object-fit: contain;
    position: absolute;
    left: 5.6rem;
    bottom: 0;
  }

  &__title {
    flex-shrink: 0;
    width: 14.55rem;
    height: 4.55rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__button {
    width: 15.5rem;
    height: 5.5rem;
    text-indent: 101%;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    display: block;
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .publicationBanner {
    &__textContainer {
      padding-left: 0;
    }

    &__title {
      width: 100% !important;
      margin-bottom: 3.25rem;
    }

    &__button {
      margin: 0 auto;
    }

    &__lightImg {
      width: 9.1rem;
      height: 11.5rem;
    }
  }
}
</style>
